import moment from "moment/moment";
import type { OrderTypeInfo, RestaurantType } from "~/types/entities/restaurant";
export function dateTimeFormater(date: string, time: string) {
  if (!date || !time) {
    return "Schedule";
  }
  const dateTimeString = `${date} ${time}`;
  // eslint-disable-next-line import/no-named-as-default-member
  const today = moment.utc().locale("nn").startOf("day");
  // eslint-disable-next-line import/no-named-as-default-member
  const dateTime = moment.utc(dateTimeString).locale("nn");
  const formatedDate = dateTime.format("DD MMM");
  const formatedTime = dateTime.format("HH:mm");
  if (dateTime.isSame(today, "day")) {
    return `I dag kl. ${formatedTime}`;
  }
  return `${formatedDate} Kl. ${formatedTime}`;
}

export function getFormattedDates() {
  const formattedDates = [];

  for (let i = 0; i < 7; i++) {
    const date = moment().add(i, "days");

    let name;
    if (i === 0) {
      name = "Senere i dag";
    } else if (i === 1) {
      name = "I morgen";
    } else {
      // eslint-disable-next-line import/no-named-as-default-member
      name = moment.utc(date.toISOString()).locale("nn").format("dddd, D. MMMM");
    }

    const code = date.format("YYYY-MM-DD").toString();
    formattedDates.push({ name, code });
  }

  return formattedDates;
}

export function getAsapTimeAccordingToSelectedRestaurant(
  restaurantTimeObject: OrderTypeInfo | undefined,
) {
  if (restaurantTimeObject) {
    return moment().add(restaurantTimeObject.deliveryTime, "minutes").format("HH:mm");
    // const currentTime = moment();
    // const currentDay = currentTime.format("dddd").toLowerCase();
    // const openingTime = moment(restaurantTimeObject.openingHours.openingTime, "HH:mm");
    // const closingTime = moment(restaurantTimeObject.openingHours.closingTime, "HH:mm");

    // if (currentTime.isBefore(openingTime)) {
    //   const deliveryTime = moment(openingTime).add(restaurantTimeObject.deliveryTime, "minutes");
    //   return deliveryTime.format("HH:mm");
    // } else if (currentTime.isAfter(closingTime)) {
    //   const nextDay = getNextDay(currentDay);
    //   const nextDayHours = restaurantTimeObject.openingHoursOfTheWeek.find(
    //     (day) => day.day.toLowerCase() === nextDay,
    //   );
    //
    //   if (nextDayHours) {
    //     const nextOpeningTime = moment(nextDayHours.openingTime, "HH:mm").add(1, "day");
    //     const deliveryTime = moment(nextOpeningTime).add(
    //       restaurantTimeObject.deliveryTime,
    //       "minutes",
    //     );
    //     return deliveryTime.format("HH:mm");
    //   }
    // } else {

    // }
  }
}

export const localizeDate = (date: string, format: "month" | "full" = "full") => {
  const givenDate = new Date(date);
  const currentYear = new Date().getFullYear();
  const options: Intl.DateTimeFormatOptions = {};

  if (format === "month") {
    if (givenDate.getFullYear() === currentYear) {
      options.month = "long";
    } else {
      options.month = "long";
      options.year = "numeric";
    }
  } else {
    // Customize to show only day, month, and year
    options.day = "numeric";
    options.month = "long";
    options.year = "numeric";
  }

  return new Intl.DateTimeFormat("no-NO", options).format(givenDate);
};
export const isRestaurantOPenNow = (restaurant: RestaurantType) => {
  const currentTime = moment();
  const openingTime = moment(
    restaurant.orderTypeInfo.find((i) => i.type === "TAKEAWAY")?.openingHours.openingTime,
    "HH:mm",
  );
  const closingTime = moment(
    restaurant.orderTypeInfo.find((i) => i.type === "TAKEAWAY")?.openingHours.closingTime,
    "HH:mm",
  );
  return !(currentTime.isBefore(openingTime) || currentTime.isAfter(closingTime));
};
